import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import { Auth } from '@layouts';
import { LinkWithQuery } from '@components';
import useUser from '@hooks/useUser';
import { Stack } from '@mui/material';
import useABTesting from 'hooks/useABTesting';

const SignUp = () => {
  const { t } = useABTesting();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [terms, setTerms] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState([]);
  const { session, signUp } = useUser();
  const { isError, isLoading, error, mutate } = signUp;

  useEffect(() => {
    if (session) {
      navigate('/');
    }
  }, []);

  const handleSubmit = () => {
    const errors = [];
    if (!email) {
      errors.push('email');
    }
    if (!name) {
      errors.push('name');
    }
    if (!password || password.length < 6) {
      errors.push('password');
      errors.push('passwordLength');
    }
    if (!terms) {
      errors.push('terms');
    }

    if (errors.length) {
      setErrors(errors);
    } else {
      setErrors([]);
      mutate({
        email: email.trim(),
        name,
        password: password.trim(),
        code: code.toUpperCase(),
      });
    }
  };

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const textFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      overflow: 'hidden',
    },
  };
  return (
    <Auth>
      <Stack
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="primary"
          component="h1"
          align="left"
          variant="h4"
          sx={{ width: '100%', mb: 1 }}
        >
          {t('pages.sign_up.title')}
        </Typography>
        <Box component="form">
          <TextField
            key="name"
            margin="normal"
            autoComplete="name"
            name="name"
            required
            fullWidth
            sx={textFieldStyle}
            id="name"
            placeholder={t('components.input.name.placeholder')}
            autoFocus
            onChange={(e) => setName(e.target.value)}
            error={errors.indexOf('name') !== -1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            key="email"
            margin="normal"
            required
            fullWidth
            sx={textFieldStyle}
            id="email"
            placeholder={t('components.input.email.placeholder')}
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            error={errors.indexOf('email') !== -1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            key="password"
            margin="normal"
            required
            fullWidth
            sx={textFieldStyle}
            name="password"
            placeholder={t('components.input.password.placeholder')}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            inputProps={{
              autoComplete: 'new-password',
            }}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.indexOf('password') !== -1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {passwordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            key="code"
            margin="normal"
            name="code"
            required
            fullWidth
            sx={textFieldStyle}
            id="code"
            placeholder={t('components.input.code.placeholder')}
            onChange={(e) => setCode(e.target.value)}
            error={errors.indexOf('code') !== -1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setTerms(e.target.value)}
                name="terms"
                value="terms"
                color={errors.indexOf('terms') !== -1 ? 'error' : 'primary'}
              />
            }
            label={
              <>
                <Typography
                  color={errors.indexOf('terms') !== -1 ? 'error' : 'black'}
                  sx={{
                    display: 'inline',
                  }}
                  variant="body2"
                >
                  {t('pages.sign_up.terms1')}
                </Typography>
                <LinkWithQuery
                  target="_blank"
                  to="https://thenewsroom.ai/terms-of-use/"
                >
                  <Typography
                    color={errors.indexOf('terms') !== -1 ? 'error' : 'primary'}
                    sx={{
                      textDecoration: 'underline',
                      display: 'inline',
                    }}
                    variant="body2"
                  >
                    {t('pages.sign_up.terms2')}
                  </Typography>
                </LinkWithQuery>
              </>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('pages.sign_up.button')}
          </Button>
          {errors.indexOf('passwordLength') !== -1 ? (
            <Typography
              sx={{ mb: 2 }}
              align="center"
              color="error"
              variant="body2"
            >
              Your password must be at least 6 characters long
            </Typography>
          ) : null}
          {isError ? (
            <Typography
              sx={{ mb: 2 }}
              align="center"
              color="error"
              variant="body2"
            >
              {error.message === 'Database error saving new user'
                ? 'Sorry, the invite code you entered is not valid'
                : error.message}
            </Typography>
          ) : null}
          <LinkWithQuery to="/sign-in">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Typography variant="body2">
                {t('pages.sign_up.sign_in_text')}
              </Typography>
              <Typography
                color="primary"
                sx={{ display: 'inline', textDecoration: 'underline' }}
                fontWeight={600}
              >
                {t('pages.sign_up.sign_in_button')}
              </Typography>
            </Stack>
          </LinkWithQuery>
        </Box>
      </Stack>
    </Auth>
  );
};

export default SignUp;
